import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import React from "react";
import Benefits from "../../../../components/Benefits/Benefits";
import Divider from "../../../../components/Divider/Divider";
import InvestorsLayout from "../../../../components/InvestorsLayout";
import RelatedServices from "../../../../components/RelatedServices/RelatedServices";
import VideoFeaturePanel from "../../../../components/VideoFeaturePanel/VideoFeaturePanel";

const MultinationalPension = ({ location }) => {
  const {
    data,
    data: {
      benefits: benefitsData,
      services: relatedServicesData,
      Articles: articlesData,
      topics,
      client_types,
      filter_types,
    },
  } = useStaticQuery(
    graphql`
      {
        data: strapiSolutionsInvestorsMultinationalPension {
          topics {
            id
            type
          }
          client_types {
            id
            Type
          }
          filter_types {
            type
          }
          Articles {
            articlesHeading
            insights {
              id
            }
            case_studies {
              id
            }
            press_releases {
              id
            }
            events {
              id
            }
          }
          metaData {
            pageTitle
            metadescription
          }
          faqLink {
            faq_category {
              category
            }
            text
          }
          services {
            shortDesc
            Image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    webpOptions: { quality: 100 }
                    placeholder: TRACED_SVG
                  )
                }
              }
              alternativeText
            }
          }
          benefits {
            Copy
            Title
            Icon {
              localFile {
                publicURL
              }
            }
          }
          featurePanel {
            copy
            heading
            headerImage {
              localFile {
                publicURL
              }
            }
            video {
              video {
                localFile {
                  publicURL
                }
              }
              transcript {
                localFile {
                  id
                  publicURL
                }
              }
            }
            mainImage {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    `
  );

  return (
    <InvestorsLayout
      currentPath={location.pathname}
      seo={{
        title: data.metaData?.pageTitle,
        description: data.metaData?.metadescription,
        image: data.featurePanel.headerImage.localFile.publicURL,
      }}
    >
      <VideoFeaturePanel data={data.featurePanel} />
      <Benefits benefitsData={benefitsData} faqLink={data.faqLink} />
      <Divider />
      <RelatedServices
        relatedServicesData={relatedServicesData}
        articlesData={articlesData}
        tagTypes={filter_types}
        topics={topics}
        client_types={client_types}
      />
    </InvestorsLayout>
  );
};

export default MultinationalPension;
